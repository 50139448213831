/* Global Styles */
body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  h1 {
    font-size: 32px;
    font-family: "Julius Sans One", sans-serif;
    margin: 0;
  }
  h2 {
    font-size: 24px;
    font-family: "Julius Sans One", sans-serif;
    margin: 0;
  }
  
  /* Header Styles */
  header {
    background-color: white;
    color: #012774;
    padding: 0.5rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1000;
    height: 60px;
  }
  
  header .logo {
    height: 100%;
    max-height: 60px;
    width: auto;
  }
  
  /* Navigation Links */
  nav ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  nav ul li {
    margin-left: 20px;
  }
  
  nav ul li a {
    color: #012774;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  nav ul li a:hover {
    color: #f39c12;
  }
  
  /* Keep original style, but add hamburger for small screens */
  .hamburger {
    display: none; /* Hidden by default for larger screens */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: none;
    border: none;
  }
  
  .hamburger-line {
    width: 25px;
    height: 3px;
    background-color: #012774;
    margin: 3px 0;
    transition: all 0.3s ease;
  }
  
  /* Mobile menu */
  @media (max-width: 768px) {
    nav ul {
      display: none; /* Hide full nav links for small screens */
      flex-direction: column;
      text-align: right;
      padding-right: 30px;
      width: 100%;
      background-color: white;
      position: absolute;
      top: 60px;
      left: 0;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    }
  
    nav ul.show {
      display: flex; /* Show nav links when menu is toggled */
      padding-right: 30px;
    }
  
    /* .hamburger {
      display: flex; 
    } */
    .nav-links-mobile {
        display: none;
        flex-direction: column;
        list-style: none;
        text-align: left; 
        background-color: white;
        position: absolute;
        top: 60px;
        right: 0; /* Align menu with the right side of the button */
        padding: 1rem 1.5rem; /* Add padding for better spacing */
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      }
      
      /* Show the mobile menu when the "show" class is applied */
      .nav-links-mobile.show {
        display: flex;
      }
      
      /* Adjust the position of the hamburger button */
      .hamburger {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end; /* Align the button to the right */
        cursor: pointer;
        background: none;
        border: none;
        position: relative;
        right: 10px; /* Add some space from the right */
      }
      
      /* Hamburger line adjustments */
      .hamburger-line {
        width: 25px;
        height: 3px;
        background-color: #012774;
        margin: 3px 0;
        transition: all 0.3s ease;
      }
  }
  
  /* General Section Styling */
  section {
    scroll-margin-top: 80px;
    min-height: 100vh;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .home {
    background-size: cover;
    background-position: center;
    color: #FFFFFF;
    text-align: center;
  }
  /* Button Styling */
.explore-button {
    padding: 0.75rem 1.5rem;
    background-color: #f39c12;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 1rem;
}
/* Products Section */
.products {
    margin-top: 80px;
    background-size: cover;
    background-position: center;
    color: white;
    padding: 3rem;
}
.contact {
    background-size: cover;
    background-position: center;
    color: #FFFFFF;
    padding: 3rem;
    text-align: center;
}

.explore-button:hover {
    background-color: #d35400;
}
/* Responsive Image Styling */
img.responsive-img {
    width: 100%; /* Image scales to the full width of its container */
    height: auto;
}

img.partial-img-left, img.partial-img-right {
    object-fit: cover; /* Ensures only a part of the image is displayed */
    width: 100%; /* Default width */
    height: auto; /* Maintains aspect ratio */
}

/* Media Queries for Image Adjustments */
@media (min-width: 768px) {
    img.partial-img-left {
        object-position: left; /* Shows the left part of the image */
    }
    img.partial-img-right {
        object-position: right; /* Shows the right part of the image */
    }
}

@media (max-width: 768px) {
    img.responsive-img {
        width: 100%; /* Image fits full width on smaller screens */
    }

    img.partial-img-left, img.partial-img-right {
        object-position: center; /* Centers image on smaller screens */
    }
}
  /* Keep other sections intact, but adjust for small screens */
  @media (max-width: 768px) {
    .home {
      padding: 1rem;
      background-position: center;
      background-size: cover;
    }
  
    .about, .values, .products, .contact {
      padding: 1.5rem;
    }
  
    h1 {
      font-size: 28px; /* Adjust font sizes for mobile */
    }
  
    h2 {
      font-size: 20px;
    }
  }
  